
export default {
  name: 'NavbarWhite',
  props: {
    title: {
      type: String,
      default: ''
    },
    top: {
      type: String,
      default: ''
    },
    btnQr: {
      type: Boolean,
      default: false
    }
  }
}
