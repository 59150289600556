
export default {
  components: {
    drawerLogin: () => import("./gallery-assets/login-drawer.vue"),
  },
  props: {
    isLogin: {
      type: Boolean | undefined,
    },
    isInput: {
      type: Boolean,
      default: false,
    },
    medias: {
      type: Array,
      default: [],
    },
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawerLogin: false,
      isAllowed: false,
      images: [],
      ImageCount: 0,
      gridClassName: {
        figure: "",
        imageContainer: "",
      },
    };
  },
  watch: {
    medias() {
      this.ImageCount = this.medias.length;
      if (!this.isInput)
        this.images =
          this.ImageCount <= 5 ? this.medias : this.medias.slice(0, 5);
      this.imageFormationClassGenerator();
    },
  },
  mounted() {
    this.ImageCount = this.medias.length;
    this.images = this.ImageCount <= 5 ? this.medias : this.medias.slice(0, 5);
    this.imageFormationClassGenerator();
  },
  methods: {
    handleOpenImage() {
      if (this.isInput) return;
      this.isAllowed = true;
      if (this.isLogin === false) {
        this.isAllowed = false;
        this.drawerLogin = true;
      } else {
      }
    },

    imageFormationClassGenerator() {
      const count = this.medias.length;
      switch (count) {
        case 1:
          this.gridClassName = {
            figure: "image-1",
            imageContainer: "child-gallery-1",
            firstImage: "",
          };
          break;
        case 2:
          this.gridClassName = {
            figure: "image-2",
            imageContainer: "child-gallery-2",
            firstImage: "",
          };
          break;
        case 3:
          this.gridClassName = {
            figure: "image-3",
            imageContainer: "child-gallery-3",
            firstImage: "first-gallery-child",
          };
          break;
        case 4:
          this.gridClassName = {
            figure: "image-4",
            imageContainer: "child-gallery-4",
            firstImage: "",
          };
          break;
        case 5:
          this.gridClassName = {
            figure: "image-5",
            imageContainer: "child-gallery-5",
            firstImage: "first-gallery-child-more",
          };
          break;
        default:
          this.gridClassName = {
            figure: "image-5",
            imageContainer: "child-gallery-5",
            firstImage: "first-gallery-child-more",
          };
          break;
      }
    },
    clickHandler(index) {
      this.$emit("deleteImage", index);
    },
  },
};
